import PropTypes from 'prop-types'
import Text from '/src/components/@common.blocks/Text'

const Form = ({
  title,
  description,
  children,
  footer,
  onSubmit,
  className,
}) => {
  const formClassName = 'form' + (className ? ` ${className}` : '')

  const handleOnSubmit = async (e) => {
    if (onSubmit) onSubmit(e)
  }

  return (
    <form className={formClassName} onSubmit={handleOnSubmit}>
      <div className='form__header'>
        <img src='/logo-white.png' alt='rariscan' />
      </div>
      <div className='form__body'>
        <div className='form__title'>
          <h2>{title}</h2>
          {description && (
            <div
              className='form__description'
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div>
        <div className='form__content'>{children}</div>
        <Text
          component='span'
          size='sm'
          color='light-gray'
          className='text-center form__footer'
        >
          {footer}
        </Text>
      </div>
    </form>
  )
}

Form.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.any,
  footer: PropTypes.any,
  onSubmit: PropTypes.func,
}

export default Form
