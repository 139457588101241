import Layout from '/src/components/@common.blocks/Layout'
import LoginForm from '/src/components/@sections/LoginForm'

const LoginPage = () => {
  return (
    <Layout title='Rariscan Login Page'>
      <LoginForm />
    </Layout>
  )
}

export default LoginPage
