import { useContext } from 'react'
import toast from 'react-hot-toast'
import axios from 'axios'
import { useRouter } from 'next/router'

import SessionContext from '/src/hooks/useSession'
import EthereumContext from '/src/hooks/useEthereum'

import Form from '/src/components/@common.blocks/Form'
import Button from '/src/components/@common.blocks/Button'
import { CoreApi } from '/src/helpers/CoreApi'

const LoginForm = () => {
  const { setUser } = useContext(SessionContext)
  const { connectWallet, personalSign, account, detectProvider } = useContext(
    EthereumContext
  )

  const router = useRouter()

  const loginHandler = async (walletType) => {
    try {
      const publicAddress = account ? account : await connectWallet(walletType)
      if (!publicAddress) return

      const { provider } = (await detectProvider(walletType)) || {}
      if (!provider) throw new Error('Wallet not detected')

      const { msgToSign } = await CoreApi.walletConnect(publicAddress)
      const signature = await personalSign(publicAddress, msgToSign, provider)

      await axios.post('/api/v1/auth/login', {
        address: publicAddress,
        signature,
      })
      setUser({ address: publicAddress })
      if (router) await router.replace('/')
    } catch (error) {
      console.log(error)
      toast.error(error.message)
    }
  }

  return (
    <Form
      className='login-form'
      title='Sign in with your wallet'
      description={`Sign in with one of available wallet providers or create a new wallet. <a>What is a wallet?</a>`}
      footer='We do not own your private keys and cannot access your funds without your confirmation.'
    >
      <Button
        className='login-form__metamask'
        onClick={() => loginHandler('metamask')}
      >
        Sign in with Metamask
      </Button>
    </Form>
  )
}

export default LoginForm
